import classNames from "classnames"
import { useEffect, useState } from "react"
import { Task } from "../../../../types"
import { Button } from "../../../components/button/Button"
import { DeleteIcon, IconButton } from "../../../components/button/IconButton"
import { Input } from "../../../components/form/input"
import { deleteTask, finishTask } from "../../../fetch/tasks"
import { formatTime } from "../../../lib/formatTime"
import { formatTimeDelta } from "../../../lib/formatTimeDelta"
import styles from "./sidepanel.module.css"
import { isBitus } from "../../../../config/urls"
import { useWatcher } from "../../../lib/state/useWatcher"
import { TenantFeature, tenantState } from "../../../store/tenant"
import { toast } from "react-toastify"
import { Confirm } from "../../../components/confirm/Confirm"

type Props = {
  task?: Task
  onClose: (reload?: boolean) => void
}

export const ConfirmTask = ({ task, onClose }: Props) => {
  const {
    settings: { features },
  } = useWatcher(tenantState)
  const [timeUsed, setTimeUsed] = useState<{ hours: number; minutes: number }>({
    hours: Math.floor((task?.timeUsed ?? 0) / 60),
    minutes: (task?.timeUsed ?? 0) % 60,
  })
  const units = task?.unitsUsed ?? 0

  const salary = (task?.salaryUsed ?? 0) / 100
  const unitsPredicted = task?.unitsPredicted ?? 0
  const salaryPredicted = ((task?.salaryPredicted ?? 0) / 100).toFixed(2)
  const [unitsUsed, setUnitsUsed] = useState<string>(`${units}`)
  const [salaryUsed, setSalaryUsed] = useState<number>(salary)

  const handleSave = async () => {
    if (!task) {
      return
    }
    const body: any = {
      status: 1,
      timeUsed: timeUsed.hours * 60 + timeUsed.minutes,
    }

    if (features[TenantFeature.Salary]) {
      body.salayUsed = salaryUsed * 100
    }
    if (features[TenantFeature.Units]) {
      body.unitsUsed = +unitsUsed || 0
    }
    try {
      await finishTask(task.id, body)
      toast("Uzdevums veiksmīgi apstiprināts!", { type: "success" })
      onClose(true)
    } catch (err) {
      console.log(err)
    }
  }
  const handleDeleteTask = async () => {
    if (task?.id) {
      await deleteTask(task.id)
      onClose(true)
    }
  }
  useEffect(() => {
    setUnitsUsed(`${isBitus ? units : units / 100}`)
    setSalaryUsed(salary)
    setTimeUsed({
      hours: Math.floor((task?.timeUsed ?? 0) / 60),
      minutes: (task?.timeUsed ?? 0) % 60,
    })
  }, [task, units, salary])

  return (
    <div className={classNames(styles.sidepanel, task ? styles.open : "")}>
      <div className={styles.wrap}>
        <div className={styles.close}>
          <Button variant="inline" onClick={() => onClose()}>
            Aizvērt
          </Button>
        </div>
        <div className={styles.title}>{task?.projectName} </div>
        <div className={styles.subTitle}>{task?.subProjectName} </div>

        <div className={styles.tableWrap}>
          <table className="main">
            <thead>
              <tr>
                <td>Darbinieks</td>
                <td>Darbs</td>
                <td>Pavadītais laiks</td>
                {features[TenantFeature.Salary] && <td>Nopelnītais</td>}
                {features[TenantFeature.Units] && <td>Vienības</td>}
                <td>Datums</td>
                <td>Izveidots</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{task?.workerName}</td>
                <td>{task?.operationName}</td>
                <td>{formatTimeDelta(task?.timeUsed ?? 0)}</td>
                {features[TenantFeature.Salary] && <td>{salary}</td>}
                {features[TenantFeature.Units] && <td>{units}</td>}
                <td>{task?.date ? task.date : "-"}</td>
                <td>{task?.createdAt && formatTime(task.createdAt)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {task?.comment ? (
          <>
            <label style={{ fontSize: 16, marginTop: 16, display: "block" }}>
              Piezīmes
            </label>
            <div className={styles.blockQuote}>{task?.comment}</div>
          </>
        ) : null}
        <div className={styles.inputWrap}>
          <Input
            label="Stundas"
            value={timeUsed.hours}
            onChange={(e) =>
              setTimeUsed({
                ...timeUsed,
                hours: +e.target.value,
              })
            }
          />
          <Input
            label="Minūtes"
            value={timeUsed.minutes}
            onChange={(e) =>
              setTimeUsed({
                ...timeUsed,
                minutes: +e.target.value,
              })
            }
          />
        </div>
        <div className={styles.inputWrap}>
          {features[TenantFeature.Units] && (
            <Input
              label={`Vienības (paredzēts ${unitsPredicted})`}
              value={unitsUsed}
              onChange={(e) => setUnitsUsed(e.target.value)}
            />
          )}

          {features[TenantFeature.Salary] && (
            <Input
              label={`Summa (aprēķins ${salaryPredicted})`}
              value={salaryUsed}
              onChange={(e) => setSalaryUsed(+e.target.value)}
            />
          )}
        </div>
        <div className={styles.buttons}>
          <Confirm
            Trigger={(props) => (
              <IconButton {...props}>{DeleteIcon}</IconButton>
            )}
            onConfirm={handleDeleteTask}
          >
            <h3>Dzēst</h3>
            <p>Vai tiešām vēlieties dzēst?</p>
          </Confirm>

          <div style={{ display: "flex", gap: 8 }}>
            <Button variant="secondary" onClick={() => onClose()}>
              Atcelt
            </Button>
            <Button onClick={handleSave}>Saglabāt</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
