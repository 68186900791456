import classNames from "classnames"
import styles from "./sidepanel.module.css"
import { Button } from "../../../components/button/Button"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getUsers } from "../../../fetch/users"
import { getAllProjects } from "../../../fetch/projects"
import { useFetchHook } from "../../../lib/hooks/fetchHook"
import { Portal } from "../../../components/portal/Portal"
import { getDistricts } from "../../../fetch/districts"
import { Combobox } from "@/components/combobox/Combobox"
import { Label } from "@/components/ui/label"
import { DatePicker } from "@/components/datepicker/DatePicker"

type AvailableFilters =
  | "statuses"
  | "from"
  | "to"
  | "workers"
  | "projects"
  | "status"
  | "districts"

export const Filters = ({
  onFiltersChange,
  availableFilters = ["from", "to", "workers", "projects"],
  show,
  initialFilters,
}: {
  onFiltersChange: (filters: Record<string, any>) => void
  availableFilters: AvailableFilters[]
  show: boolean
  initialFilters?: Record<string, string | string[] | undefined>
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { data: workers } = useFetchHook<{ items: any[] }>(getUsers, {
    defaultValue: { items: [] },
  })
  const { data: districts } = useFetchHook(getDistricts, {
    defaultValue: [],
  })
  const { data: projects } = useFetchHook(getAllProjects, { defaultValue: [] })
  const [filters, setFilters] = useState(initialFilters ?? {})

  const workerItems = useMemo(() => {
    return [{ name: "Visi darbinieki", id: undefined }, ...workers?.items]
  }, [workers])

  const projectItems = useMemo(() => {
    return [{ name: "Visi projekti", id: undefined }, ...projects]
  }, [projects])

  const districtsItems = useMemo(() => {
    return [{ name: "Visi iecirkņi", id: undefined }, ...districts]
  }, [districts])

  useEffect(() => {
    onFiltersChange(filters)
  }, [filters, onFiltersChange])

  useEffect(() => {
    if (!show) setIsOpen(false)
  }, [show])

  const selectMultipleOptions = useCallback(
    (field: string, options?: any[]) => {
      const opts = [...(options ?? [])]

      if (opts.includes(undefined)) {
        setFilters((f) => ({ ...f, [field]: [] }))
        return
      }
      setFilters((f) => ({ ...f, [field]: opts }))
    },
    [setFilters]
  )

  return (
    show && (
      <>
        <Portal id="tabs-right">
          <Button onClick={() => setIsOpen(true)}>Filtrēt</Button>
        </Portal>
        <div
          className={classNames(
            styles.sidepanel,
            styles.small,
            isOpen ? styles.open : ""
          )}
        >
          <div className={styles.wrap}>
            <div className={styles.close}>
              <Button variant="inline" onClick={() => setIsOpen(false)}>
                Aizvērt
              </Button>
            </div>
            <div className={styles.title}>Filtrēt</div>
            <div className={styles.formWrap}>
              {availableFilters.includes("from") && (
                <span>
                  <Label>Datums no:</Label>
                  <DatePicker
                    value={filters.from as string}
                    onChange={(date) =>
                      setFilters((f) => ({ ...f, from: date }))
                    }
                  />
                </span>
              )}
              {availableFilters.includes("to") && (
                <span>
                  <Label>Datums līdz:</Label>
                  <DatePicker
                    value={filters.to as string}
                    onChange={(date) => setFilters((f) => ({ ...f, to: date }))}
                  />
                </span>
              )}
              {availableFilters.includes("statuses") && (
                <span>
                  <Label>Status</Label>
                  <Combobox
                    options={[
                      { id: undefined, name: "Visi" },
                      { id: 1, name: "Pieejams" },
                      { id: 2, name: "Sākts" },
                      { id: 3, name: "Pabeigts" },
                      { id: 4, name: "Kļūda" },
                      { id: 5, name: "Apturēts" },
                      { id: 6, name: "Arhivēts" },
                    ]}
                    selected={filters.statuses ? [filters.statuses] : []}
                    onChange={(value) =>
                      setFilters((f) => ({ ...f, statuses: value }))
                    }
                  />
                </span>
              )}
              {availableFilters.includes("status") && (
                <span>
                  <Label>Status</Label>
                  <Combobox
                    selected={filters.status ? [filters.status] : []}
                    single
                    options={[
                      { id: 0, name: "Jāapstirpina" },
                      { id: 1, name: "Apstiprinātie" },
                      { id: "0,1", name: "Visi" },
                    ]}
                    onChange={(value) => selectMultipleOptions("status", value)}
                  />
                </span>
              )}
              {availableFilters.includes("workers") && (
                <span>
                  <Label>Darbinieki</Label>
                  <Combobox
                    selected={
                      filters.workers?.length ? (filters.workers as []) : []
                    }
                    options={workerItems}
                    onChange={(value) =>
                      selectMultipleOptions("workers", value)
                    }
                  />
                </span>
              )}
              {availableFilters.includes("projects") && (
                <span>
                  <Label>Projekts</Label>
                  <Combobox
                    selected={
                      filters.projects?.length ? (filters.projects as []) : []
                    }
                    options={projectItems}
                    onChange={(value) =>
                      selectMultipleOptions("projects", value)
                    }
                  />
                </span>
              )}
              {availableFilters.includes("districts") && (
                <span>
                  <Label>Iecirknis</Label>
                  <Combobox
                    selected={
                      filters.districts?.length ? (filters.districts as []) : []
                    }
                    options={districtsItems}
                    onChange={(value) =>
                      selectMultipleOptions("districts", value)
                    }
                  />
                </span>
              )}
              {/*<Select
            label={isBitus ? "Tirgi" : "Process"}
            options={opGroupItems}
            onChange={(e) =>
              setFilters((f) => ({ ...f, opGroups: e.target.value }))
            }
          />
          */}
            </div>
          </div>
        </div>
      </>
    )
  )
}
