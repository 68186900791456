import { FormEvent, useEffect, useState } from "react"
import { EditIcon, IconButton } from "../../../components/button/IconButton"
import { Sidepanel } from "../../../components/sidepanel/Sidepanel"
import { Input } from "../../../components/form/input"
import { Task } from "../../../../types"
import { Button } from "../../../components/button/Button"
import { updateGeneralTask } from "../../../fetch/projects"
import { toast } from "react-toastify"

export const EditTask = ({
  task,
  onSave,
}: {
  task: Task
  onSave?: () => void
}) => {
  const [open, setOpen] = useState(false)

  const [errors, setErrors] = useState<Record<string, boolean>>({})
  const [time, setTime] = useState(task.timePredicted ?? "")
  const [units, setUnits] = useState(task.unitsPredicted)
  const [salary, setSalary] = useState(task.salaryPredicted)
  const [normative, setNormative] = useState(task.normative)

  useEffect(() => {
    const values = {
      time,
      units,
      salary,
      normative,
    }

    const nextErrors: Record<string, boolean> = {}
    Object.keys(values).forEach((key) => {
      if (values[key as keyof typeof values]) {
        nextErrors[key] = false
      }
    })
    setErrors((err) => ({ ...err, ...nextErrors }))
  }, [time, units, salary, normative])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const nextErrors: Record<string, boolean> = {}
    // if (!time) {
    //   nextErrors.time = true
    // }

    if (!units) {
      nextErrors.units = true
    }

    // if (!salary) {
    //   nextErrors.salary = true
    // }
    //
    // if (!normative) {
    //   nextErrors.normative = true
    // }
    //
    if (Object.keys(nextErrors).length) {
      setErrors(nextErrors)
      return
    }

    try {
      await updateGeneralTask(task.id, {
        unitsPredicted: units,
        // timePredicted: time,
        // salaryPredicted: salary,
        // normative,
      })
      toast("Veiksmīgi izlabots", { type: "success" })
      onSave?.()
      setOpen(false)
    } catch (err) {
      toast("Labošana nesekmīga, lūdzu mēģiniet vēlreiz", { type: "error" })
    }
  }

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setOpen(true)
        }}
      >
        {EditIcon}
      </IconButton>
      <Sidepanel open={open} onClose={() => setOpen(false)}>
        <form onSubmit={handleSubmit}>
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Input
              label={"Vienības"}
              value={units}
              type="number"
              onChange={(e) => setUnits(+e.target.value)}
              error={errors.units}
            />
            {/* <Input
              label={"Paredzētais laiks"}
              value={time}
              onChange={(e) => setTime(+e.target.value)}
              error={errors.time}
            />
            <Input
              label={"Summa"}
              value={salary}
              type="number"
              onChange={(e) => setSalary(+e.target.value)}
              error={errors.salary}
            />
            <Input
              label={"Normatīvs"}
              type="number"
              value={normative}
              onChange={(e) => setNormative(+e.target.value)}
              error={errors.normative}
            />
            <Input
              label={"Paredzētais laiks"}
              value={time}
              onChange={(e) => setTime(+e.target.value)}
              error={errors.time}
            />
            */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
            }}
          >
            <div />
            <div style={{ display: "flex", gap: 8 }}>
              <Button
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault()
                  setOpen(false)
                }}
              >
                Atcelt
              </Button>
              <Button type="submit">Saglabāt</Button>
            </div>
          </div>
        </form>
      </Sidepanel>
    </>
  )
}
