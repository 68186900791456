import { Project, Task } from "../../types"
import { formatDate } from "../lib/formatDate"
import { prepareQuery } from "../lib/prepareQuery"
import { userState } from "../store/user"
import { localFetch } from "./localFetch"

export const getProjects = (filters: Record<string, any>) => {
  const q = prepareQuery(filters)
  return localFetch(`/api/views/projects${q}`)
}

export const getUserProjects = () => {
  return localFetch(`/api/views/user_projects`)
}

export const getUserProjectById = (id: number | string) => {
  return localFetch(`/api/views/user_projects/${id}`)
}

export const getUserSubProjectById = (id: number, subId: number) => {
  return localFetch(`/api/views/user_projects/${id}/${subId}`)
}

export const getProjectsList = () => {
  return localFetch(`/api/projects`)
}

export const getProject = (id: string) => {
  return localFetch(`/api/views/projects/${id}`)
}

export const getProjectOperations = (id: string, project: string) => {
  return localFetch(`/api/views/projects/${id}/${project}`)
}
export const getUserProjectOperations = (id: string, project: string) => {
  return localFetch(`/api/views/user_projects/${id}/${project}`)
}

export const getOps = (id: string) => {
  return localFetch(`/api/ops/${id}`)
}

export const getOperation = (
  projectId: string | number,
  subprojectId: string | number,
  operationId: string | number,
) => {
  return localFetch(
    `/api/views/projects/${projectId}/${subprojectId}/${operationId}`,
  )
}

export const getAllProjects = () => {
  return localFetch(`/api/projects`)
}

export const getAllOpGroups = () => {
  return localFetch(`/api/op_groups`)
}

export const updateProject = (id: Project["id"], body: Partial<Project>) => {
  return localFetch(`/api/projects/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
  })
}

export const updateGeneralTask = (id: Task["id"], body: Partial<Task>) => {
  return localFetch(`/api/ops/${id}`, {
    method: "PUT",
    body: JSON.stringify(body),
  })
}
export const toggleDone = (id: Project["id"], done: boolean) => {
  return localFetch(`/api/tasks/status`, {
    method: "POST",
    body: JSON.stringify({
      operationId: id,
      date: formatDate(new Date()),
      status: done ? 3 : 2, // "3 == done", "2 == started"
    }),
  })
}
