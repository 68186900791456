"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { formatDate } from "@/lib/formatDate"

type Props = {
  value: string
  onChange: (d?: string) => void
}
export function DatePicker({ value, onChange }: Props) {
  const [open, setOpen] = React.useState(false)
  const initialValue = value ? new Date(value) : undefined
  const [date, setDate] = React.useState<Date | undefined>(initialValue)

  const handleDateChange = (date?: Date) => {
    setDate(date)
    const next = date && formatDate(date)
    onChange(next)
    setOpen(false)
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Izvēlēties datumu</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" style={{ zIndex: 999 }}>
        <Calendar
          mode="single"
          selected={date}
          onSelect={handleDateChange}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
